<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 d-flex justify-content-start mb-5">
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisWeek"
              @click="Filter('thisWeek')"
            >
              Minggu Ini
            </button>
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisMonth"
              @click="Filter('thisMonth')"
            >
              Bulan Ini
            </button>
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.lastMonth"
              @click="Filter('lastMonth')"
            >
              Bulan Kemarin
            </button>
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisYear"
              @click="Filter('thisYear')"
            >
              Tahun Ini
            </button>
            <!-- <button
              type="button"
              class="btn btn-outline-primary filter-btn mx-1"
              @click="periodFilter()"
            ></button> -->
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <apexchart
          class="card-rounded-bottom"
          :options="chartOptions"
          :series="series"
          type="area"
          width="100%"
          height="300"
          v-if="chartLoad"
        ></apexchart>
      </div>

      <!-- <div class="col-md-3">
        <div class="card shadow-sm rounded p-2">
          <div class="card-body p-4 text-center">
            <div class="row">
              <div class="col-md-2">
                <div
                  class="text-center mr-2"
                  style="
                    border-radius: 25px;
                    background-color: #24559033;
                    width: 50px;
                  "
                >
                  <i
                    class="fas fa-user"
                    style="margin: 15px; font-size: 20px; color: #245590"
                  ></i>
                </div>
              </div>
              <div class="col-md-10">
                <span class="d-block" style="font-size: 14px"
                  >Total Pasien :</span
                >
                <span
                  class="d-block font-weight-bold"
                  style="font-size: 40px"
                  >{{ totalPatient }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";
import moment from "moment";
export default {
  name: "widget-12",
  data() {
    return {
      chartOptions: {},
      series: [
        {
          name: "Total Potongan Kupon",
          data: [],
        },
        {
          name: "Total Potongan Promo",
          data: [],
        },
      ],
      sum: "",
      arr: [],
      chartLoad: false,
      sumLoad: false,
      // filter
      filter: {
        start_date: "",
        end_date: "",
      },
      period: {
        thisWeek: "btn-purple",
        thisMonth: "btn-outline-purple",
        lastMonth: "btn-outline-purple",
        thisYear: "btn-outline-purple",
      },
      totalPatient: 0,

      tooltipPeriod: "thisWeek",

      // chart
      // series: [],
      chartOptions: {
        chart: {
          type: "area",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["#f47b20","#26568e"],
          // colors: ['#245590'],
        },
        xaxis: {
          categories: [],
        },
        // yaxis: {
        //   title: {
        //     text: "$ (thousands)",
        //   },
        // },
        fill: {
          opacity: 1,
        },
        colors: ["#f47b20","#26568e"],
        tooltip: {
          y: {
            formatter: function (val) {
              return parseInt(val).toLocaleString('id-ID');
            },
          },
        },
      },
    };
  },
  methods: {
    Filter(tool) {
      this.$root.$emit("filterChartPatient", tool);
      this.tooltipPeriod = tool;
      this.chartLoad = false;
      if (tool == "thisWeek") {
        this.period.thisWeek = "btn-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "thisMonth") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "lastMonth") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "thisYear") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-purple";
      }
      this.getData();

      mapGetters(["layoutConfig"]);
    },
    // generalFilter() {
    //   this.tooltipPeriod = "custom";
    //   this.chartLoad = false;
    //   this.getData();
    //   mapGetters(["layoutConfig"]);
    // },
    // periodFilter() {
    //   this.tooltipPeriod = "custom";
    // },
    // resetFilter() {
    //   this.chartLoad = false;
    //   this.filter.start_date = "";
    //   this.filter.end_date = "";
    //   this.getData();
    //   mapGetters(["layoutConfig"]);
    // },

    // Filter(tool) {
    //   if (tool == "thisWeek") {
    //     this.period.thisWeek = "btn-purple";
    //     this.period.thisMonth = "btn-outline-purple";
    //     this.period.lastMonth = "btn-outline-purple";
    //     this.period.thisYear = "btn-outline-purple";
    //   } else if (tool == "thisMonth") {
    //     this.period.thisWeek = "btn-outline-purple";
    //     this.period.thisMonth = "btn-purple";
    //     this.period.lastMonth = "btn-outline-purple";
    //     this.period.thisYear = "btn-outline-purple";
    //   } else if (tool == "lastMonth") {
    //     this.period.thisWeek = "btn-outline-purple";
    //     this.period.thisMonth = "btn-outline-purple";
    //     this.period.lastMonth = "btn-purple";
    //     this.period.thisYear = "btn-outline-purple";
    //   } else if (tool == "thisYear") {
    //     this.period.thisWeek = "btn-outline-purple";
    //     this.period.thisMonth = "btn-outline-purple";
    //     this.period.lastMonth = "btn-outline-purple";
    //     this.period.thisYear = "btn-purple";
    //   }

    //   this.$root.$emit("filterChart", tool);
    // },
    month(month) {
      let monthName;
      if (month == 1) {
        monthName = "Jan";
        // monthName = "Januari"
      } else if (month == 2) {
        monthName = "Feb";
        // monthName = "Februari"
      } else if (month == 3) {
        monthName = "Mar";
        // monthName = "Maret"
      } else if (month == 4) {
        monthName = "Apr";
        // monthName = "April"
      } else if (month == 5) {
        monthName = "Mei";
        // monthName = "Mei"
      } else if (month == 6) {
        monthName = "Jun";
        // monthName = "Juni"
      } else if (month == 7) {
        monthName = "Jul";
        // monthName = "Juli"
      } else if (month == 8) {
        monthName = "Agu";
        // monthName = "Agustus"
      } else if (month == 9) {
        monthName = "Sep";
        // monthName = "September"
      } else if (month == 10) {
        monthName = "Okt";
        // monthName = "Oktober"
      } else if (month == 11) {
        monthName = "Nov";
        // monthName = "November"
      } else if (month == 12) {
        monthName = "Des";
        // monthName = "Desember"
      }

      return monthName;
    },

    async getData(tool) {
      window.arrData = [];
      let filterParams = `?start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&filter=${this.tooltipPeriod}`;
      let response = await module.get("amount-efectivity-dashboard", `${filterParams}`);
      // console.log(response);

      let resCoupon = response.coupon.data;
      let resPromo = response.promo.data;

      let coupon = [];
      let promo = [];
      let typePeriod = "Tanggal";

      this.totalPatient = parseInt(response.total).toLocaleString("id-ID");

      // coupons
      if (
        this.tooltipPeriod != "thisYear" &&
        this.tooltipPeriod != "thisWeek"
      ) {
        let b = 0;
        let counter = 1;
        for (let a = 0; a <= resCoupon.days; a++) {
          if (typeof resCoupon.items[b] !== "undefined") {
            let monthName = this.month(resCoupon.items[b].month);

            if (resCoupon.items[b].day == counter) {
              coupon.push({
                ref_name: parseInt(resCoupon.items[b].day) + "-" + monthName,
                price: resCoupon.items[b].amount,
              });
              b++;
            } else {
              coupon.push({
                ref_name: parseInt(counter) + "-" + monthName,
                price: 0,
              });
            }
          } else {
            let month;
            if (this.tooltipPeriod == "lastMonth") {
              month = moment().subtract(1, "month").format("MM");
            } else if (this.tooltipPeriod == "thisMonth") {
              month = moment().format("MM");
            }

            month = this.month(parseInt(month));

            coupon.push({
              ref_name: parseInt(counter) + "-" + month,
              price: 0,
            });
          }
          counter++;
        }

        typePeriod = "Tanggal";
      } else if (this.tooltipPeriod == "thisYear") {
        let b = 1;
        for (let a = 0; a < 12; a++) {
          if (typeof resCoupon.items[a] !== "undefined") {
            let monthName;
            if (resCoupon.items[a].month == 1) {
              monthName = "Januari";
            } else if (resCoupon.items[a].month == 2) {
              monthName = "Februari";
            } else if (resCoupon.items[a].month == 3) {
              monthName = "Maret";
            } else if (resCoupon.items[a].month == 4) {
              monthName = "April";
            } else if (resCoupon.items[a].month == 5) {
              monthName = "Mei";
            } else if (resCoupon.items[a].month == 6) {
              monthName = "Juni";
            } else if (resCoupon.items[a].month == 7) {
              monthName = "Juli";
            } else if (resCoupon.items[a].month == 8) {
              monthName = "Agustus";
            } else if (resCoupon.items[a].month == 9) {
              monthName = "September";
            } else if (resCoupon.items[a].month == 10) {
              monthName = "Oktober";
            } else if (resCoupon.items[a].month == 11) {
              monthName = "November";
            } else if (resCoupon.items[a].month == 12) {
              monthName = "Desember";
            }

            coupon.push({
              ref_name: monthName,
              price: resCoupon.items[a].amount,
            });
          } else {
            let monthName;
            if (b == 1) {
              monthName = "Januari";
            } else if (b == 2) {
              monthName = "Februari";
            } else if (b == 3) {
              monthName = "Maret";
            } else if (b == 4) {
              monthName = "April";
            } else if (b == 5) {
              monthName = "Mei";
            } else if (b == 6) {
              monthName = "Juni";
            } else if (b == 7) {
              monthName = "Juli";
            } else if (b == 8) {
              monthName = "Agustus";
            } else if (b == 9) {
              monthName = "September";
            } else if (b == 10) {
              monthName = "Oktober";
            } else if (b == 11) {
              monthName = "November";
            } else if (b == 12) {
              monthName = "Desember";
            }

            coupon.push({
              ref_name: monthName,
              price: 0,
            });
          }

          b++;
        }

        typePeriod = "Bulan";
      } else if (this.tooltipPeriod == "thisWeek") {
        let b = 0;
        let counter = resCoupon.start;
        for (let a = 0; a <= resCoupon.days; a++) {
          let month;
          let startMonth = moment().startOf("week").format("MM");
          let monthName = this.month(parseInt(startMonth));

          if (typeof resCoupon.items[b] !== "undefined") {
            monthName = this.month(parseInt(resCoupon.items[b].month));

            coupon.push({
              ref_name: parseInt(resCoupon.items[b].day) + "-" + monthName,
              price: resCoupon.items[b].amount,
            });
            b++;
          } else {
            if (counter < resCoupon.start) {
              month = moment().add(1, "months").format("MM");
              monthName = this.month(parseInt(month));
            }
            coupon.push({
              ref_name: parseInt(counter) + "-" + monthName,
              price: 0,
            });
          }
          counter++;
        }

        typePeriod = "Tanggal";
      }
      
      // coupons
      if (
        this.tooltipPeriod != "thisYear" &&
        this.tooltipPeriod != "thisWeek"
      ) {
        let b = 0;
        let counter = 1;
        for (let a = 0; a <= resPromo.days; a++) {
          if (typeof resPromo.items[b] !== "undefined") {
            let monthName = this.month(resPromo.items[b].month);

            if (resPromo.items[b].day == counter) {
              promo.push({
                ref_name: parseInt(resPromo.items[b].day) + "-" + monthName,
                price: resPromo.items[b].amount,
              });
              b++;
            } else {
              promo.push({
                ref_name: parseInt(counter) + "-" + monthName,
                price: 0,
              });
            }
          } else {
            let month;
            if (this.tooltipPeriod == "lastMonth") {
              month = moment().subtract(1, "month").format("MM");
            } else if (this.tooltipPeriod == "thisMonth") {
              month = moment().format("MM");
            }

            month = this.month(parseInt(month));

            promo.push({
              ref_name: parseInt(counter) + "-" + month,
              price: 0,
            });
          }
          counter++;
        }

        typePeriod = "Tanggal";
      } else if (this.tooltipPeriod == "thisYear") {
        let b = 1;
        for (let a = 0; a < 12; a++) {
          if (typeof resPromo.items[a] !== "undefined") {
            let monthName;
            if (resPromo.items[a].month == 1) {
              monthName = "Januari";
            } else if (resPromo.items[a].month == 2) {
              monthName = "Februari";
            } else if (resPromo.items[a].month == 3) {
              monthName = "Maret";
            } else if (resPromo.items[a].month == 4) {
              monthName = "April";
            } else if (resPromo.items[a].month == 5) {
              monthName = "Mei";
            } else if (resPromo.items[a].month == 6) {
              monthName = "Juni";
            } else if (resPromo.items[a].month == 7) {
              monthName = "Juli";
            } else if (resPromo.items[a].month == 8) {
              monthName = "Agustus";
            } else if (resPromo.items[a].month == 9) {
              monthName = "September";
            } else if (resPromo.items[a].month == 10) {
              monthName = "Oktober";
            } else if (resPromo.items[a].month == 11) {
              monthName = "November";
            } else if (resPromo.items[a].month == 12) {
              monthName = "Desember";
            }

            promo.push({
              ref_name: monthName,
              price: resPromo.items[a].amount,
            });
          } else {
            let monthName;
            if (b == 1) {
              monthName = "Januari";
            } else if (b == 2) {
              monthName = "Februari";
            } else if (b == 3) {
              monthName = "Maret";
            } else if (b == 4) {
              monthName = "April";
            } else if (b == 5) {
              monthName = "Mei";
            } else if (b == 6) {
              monthName = "Juni";
            } else if (b == 7) {
              monthName = "Juli";
            } else if (b == 8) {
              monthName = "Agustus";
            } else if (b == 9) {
              monthName = "September";
            } else if (b == 10) {
              monthName = "Oktober";
            } else if (b == 11) {
              monthName = "November";
            } else if (b == 12) {
              monthName = "Desember";
            }

            promo.push({
              ref_name: monthName,
              price: 0,
            });
          }

          b++;
        }

        typePeriod = "Bulan";
      } else if (this.tooltipPeriod == "thisWeek") {
        let b = 0;
        let counter = resPromo.start;
        for (let a = 0; a <= resPromo.days; a++) {
          let month;
          let startMonth = moment().startOf("week").format("MM");
          let monthName = this.month(parseInt(startMonth));

          if (typeof resPromo.items[b] !== "undefined") {
            monthName = this.month(parseInt(resPromo.items[b].month));

            promo.push({
              ref_name: parseInt(resPromo.items[b].day) + "-" + monthName,
              price: resPromo.items[b].amount,
            });
            b++;
          } else {
            if (counter < resPromo.start) {
              month = moment().add(1, "months").format("MM");
              monthName = this.month(parseInt(month));
            }
            promo.push({
              ref_name: parseInt(counter) + "-" + monthName,
              price: 0,
            });
          }
          counter++;
        }

        typePeriod = "Tanggal";
      }

      this.series[0].data = coupon.map((coupon) => parseFloat(coupon.price));
      this.series[1].data = promo.map((promo) => parseFloat(promo.price));
      window.arrData = coupon.map((coupon) => String(coupon.ref_name));
      this.chartLoad = true;

      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: window.arrData,
            title: {
              text: "Periode " + typePeriod,
            },
          },
        },
      };
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    this.getData();
    if (this.chartLoad == true) {
    }

    // this.$root.$on('filterChart',(tool)=>{
    //   console.log(tool,"============ tool ================");
    //   this.Filter(tool)
    // })
    // reference; kt_stats_widget_12_chart
  },
};
</script>
