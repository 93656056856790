<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-alert
          show
          variant="light"
          class="alert alert-custom alert-white alert-shadow fade show gutter-b"
        >
          <div class="alert-icon">
            <span class="svg-icon svg-icon-lg">
              <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
            </span>
          </div>
          <div class="alert-text">
            Efektivitas Dashboard <b>{{ clinic_name }}</b>
          </div>
        </b-alert>
      </div>
    </div>

    <!-- overview -->
    <div>
      <div class="row">
        <div class="col-md-6 d-flex align-self-center">
          <span style="font-size: 15px" class="mb-0 d-block"
            ><b>RINGKASAN</b></span
          >
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12 d-flex justify-content-start mb-2">
              <button
                type="button"
                :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisWeek"
                @click="Filter('thisWeek')"
              >
                Minggu Ini
              </button>
              <button
                type="button"
                :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisMonth"
                @click="Filter('thisMonth')"
              >
                Bulan Ini
              </button>
              <button
                type="button"
                :class="'btn btn-sm filter-btn mx-1 ' + this.period.lastMonth"
                @click="Filter('lastMonth')"
              >
                Bulan Kemarin
              </button>
              <button
                type="button"
                :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisYear"
                @click="Filter('thisYear')"
              >
                Tahun Ini
              </button>
              <!-- <button
              type="button"
              class="btn btn-outline-primary filter-btn mx-1"
              @click="periodFilter()"
            ></button> -->
            </div>
          </div>
        </div>
      </div>

      <!-- first row -->
      <div class="row mb-2">
        <div class="col-md-3 py-1">
          <div class="card shadow-sm rounded p-2" style="min-height: 120px">
            <div class="card-body p-4 text-center">
              <div class="row">
                <div
                  class="col-md-4 d-flex align-self-center justify-content-center"
                >
                  <div
                    class="text-center"
                    style="
                      border-radius: 10px;
                      background-color: #24559033;
                      width: 50px;
                    "
                  >
                    <i
                      class="fas fa-tags"
                      style="margin: 15px; font-size: 20px; color: #245590"
                    ></i>
                  </div>
                </div>
                <div class="col-md-8">
                  <span class="text-teal">Transaksi Dengan Promo</span>
                  <h4 class="d-block mt-2">
                    {{ parseInt(items.countCoupon).toLocaleString("id-ID") }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3 py-1">
          <div class="card shadow-sm rounded p-2" style="min-height: 120px">
            <div class="card-body p-4 text-center">
              <div class="row">
                <div
                  class="col-md-4 d-flex align-self-center justify-content-center"
                >
                  <div
                    class="text-center"
                    style="
                      border-radius: 10px;
                      background-color: #24559033;
                      width: 50px;
                    "
                  >
                    <i
                      class="fas fa-tags"
                      style="margin: 15px; font-size: 20px; color: #245590"
                    ></i>
                  </div>
                </div>
                <div class="col-md-8">
                  <span class="text-teal">Total Potongan Promo</span>
                  <h4 class="d-block mt-2">
                    {{ parseInt(items.sumCoupon).toLocaleString("id-ID") }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3 py-1">
          <div class="card shadow-sm rounded p-2" style="min-height: 120px">
            <div class="card-body p-4 text-center">
              <div class="row">
                <div
                  class="col-md-4 d-flex align-self-center justify-content-center"
                >
                  <div
                    class="text-center"
                    style="
                      border-radius: 10px;
                      background-color: #24559033;
                      width: 50px;
                    "
                  >
                    <i
                      class="fas fa-percent"
                      style="margin: 15px; font-size: 20px; color: #245590"
                    ></i>
                  </div>
                </div>
                <div class="col-md-8">
                  <span class="text-teal">Transaksi Dengan Kupon</span>
                  <h4 class="d-block mt-2">
                    {{ parseInt(items.countPromo).toLocaleString("id-ID") }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3 py-1">
          <div class="card shadow-sm rounded p-2" style="min-height: 120px">
            <div class="card-body p-4 text-center">
              <div class="row">
                <div
                  class="col-md-4 d-flex align-self-center justify-content-center"
                >
                  <div
                    class="text-center"
                    style="
                      border-radius: 10px;
                      background-color: #24559033;
                      width: 50px;
                    "
                  >
                    <i
                      class="fas fa-percent"
                      style="margin: 15px; font-size: 20px; color: #245590"
                    ></i>
                  </div>
                </div>
                <div class="col-md-8">
                  <span class="text-teal">Total Potongan Kupon</span>
                  <h4 class="d-block mt-2">
                    {{ parseInt(items.sumPromo).toLocaleString("id-ID") }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end overview -->

    <div class="mb-2 mt-6">
      <div class="row mb-1">
        <div class="col-md-6 d-flex align-self-center">
          <span style="font-size: 15px" class="mb-0 d-block"
            ><b>STATISTIK</b></span
          >
        </div>
      </div>

      <div class="row mb-2 mt-0">
        <div class="col-md-6">
          <div class="card shadow-sm rounded p-2">
            <div class="card-body p-4 text-center">
              <PromoChart></PromoChart>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card shadow-sm rounded p-2">
            <div class="card-body p-4 text-center">
              <PromoCountChart></PromoCountChart>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-2 mt-6">
      <div class="row mb-1">
        <div class="col-md-6 d-flex align-self-center">
          <span style="font-size: 15px" class="mb-0 d-block"
            ><b>DAFTAR TERATAS</b></span
          >
        </div>
      </div>
    </div>

    <div class="row mb-2 mt-0">
      <div class="col-md-6">
        <div class="card shadow-sm rounded p-2">
          <div class="card-body p-4">
            <TablePromo></TablePromo>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card shadow-sm rounded p-2">
          <div class="card-body p-4">
            <TableCoupon></TableCoupon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.rounded {
  border-radius: 20px !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";

import PromoChart from "@/component/report-charts/PromoChart.vue";
import PromoCountChart from "@/component/report-charts/PromoCountChart.vue";
import TablePromo from "@/component/masters/promos/TableDashboardPromo.vue";
import TableCoupon from "@/component/masters/promos/TableDashboardCoupon.vue";

export default {
  data() {
    return {
      dashboardAccess: false,
      items: [],
      isOverview: false,

      period: {
        thisWeek: "btn-purple",
        thisMonth: "btn-outline-purple",
        lastMonth: "btn-outline-purple",
        thisYear: "btn-outline-purple",
      },

      // filter
      filter: {
        start_date: "",
        end_date: "",
      },
      period: {
        thisWeek: "btn-purple",
        thisMonth: "btn-outline-purple",
        lastMonth: "btn-outline-purple",
        thisYear: "btn-outline-purple",
      },
      totalPatient: 0,

      tooltipPeriod: "thisWeek",

      // table top list
      itemPromo: [],
      itemCoupon: [],
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
        },
        {
          key: "count",
          label: "Jml. Transaksi",
          sortable: true,
        },
        {
          key: "amount",
          label: "Total Potongan",
          sortable: true,
        },
      ],

      clinic_name:
        window.configuration == null
          ? "Medhop"
          : window.configuration.clinic_name,
    };
  },

  name: "dashboard",

  components: {
    PromoChart,
    PromoCountChart,
    TablePromo,
    TableCoupon,
  },

  methods: {
    Filter(tool) {
      // this.$root.$emit("filterChartPatient", tool);
      this.tooltipPeriod = tool;
      this.chartLoad = false;
      if (tool == "thisWeek") {
        this.period.thisWeek = "btn-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "thisMonth") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "lastMonth") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "thisYear") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-purple";
      }
      this.pagination();
    },

    async pagination() {
      let filterParams = `?filter=${this.tooltipPeriod}`;
      let response = await module.get("efectivity-dashboard", filterParams);

      this.isOverview = true;
      this.items = response;
    },

    // Filter(tool) {
    //   if (tool == "thisWeek") {
    //     this.period.thisWeek = "btn-purple";
    //     this.period.thisMonth = "btn-outline-purple";
    //     this.period.lastMonth = "btn-outline-purple";
    //     this.period.thisYear = "btn-outline-purple";
    //   } else if (tool == "thisMonth") {
    //     this.period.thisWeek = "btn-outline-purple";
    //     this.period.thisMonth = "btn-purple";
    //     this.period.lastMonth = "btn-outline-purple";
    //     this.period.thisYear = "btn-outline-purple";
    //   } else if (tool == "lastMonth") {
    //     this.period.thisWeek = "btn-outline-purple";
    //     this.period.thisMonth = "btn-outline-purple";
    //     this.period.lastMonth = "btn-purple";
    //     this.period.thisYear = "btn-outline-purple";
    //   } else if (tool == "thisYear") {
    //     this.period.thisWeek = "btn-outline-purple";
    //     this.period.thisMonth = "btn-outline-purple";
    //     this.period.lastMonth = "btn-outline-purple";
    //     this.period.thisYear = "btn-purple";
    //   }

    //   this.$root.$emit("filterChart", tool);
    // },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.pagination();
  },
};
</script>

<style>
.btn-outline-purple {
  border-radius: 10px !important;
  border: 1px solid #f47b20;
  color: #f47b20;
  /* border: 1px solid #5c4ea5;
  color: #5c4ea5; */
}

.btn-outline-purple:hover {
  border-radius: 10px !important;
  background-color: #f47b20;
  color: white;
  /* background-color: #5c4ea5;
  color: white; */
}

.btn-purple,
.btn-purple:hover {
  border-radius: 10px !important;
  background-color: #f47b20;
  color: white !important;
  /* background-color: #5c4ea5;
  color: white !important; */
}

.text-notes {
  color: #bfc0c5 !important;
  font-size: 10px;
}

.notes {
  position: absolute;
  bottom: 14px;
  right: 20px;
}

/* .btn-purple:hover{
  background-color: #551871;
  color: white;
} */
</style>
